.faq-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
}

.faq-page h1 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
}

.faq-item {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: background-color 0.3s;
}

.faq-item.active {
  background-color: #ddd;
}

.faq-item h3 {
  margin: 0;
}

.faq-item p {
  margin-top: 5px;
  display: none;
}

.faq-item.active p {
  display: block;
}
