.caf-button {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 50px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 45px;
}

.caf-button:hover {
  background-color: darkgreen;
}
