/* Ensure body and html take full height */
html,
body {
  height: 100%;
  margin: 0;
}

/* PeopleInvolved container with background image covering entire page */
.peopleinvolved-container {
  height: 100%;
  min-height: 100vh;
  background-image: url("../images/NFTbackground6.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: black;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}

.peopleinvolved-container h1 {
  font-size: 5rem;
  margin-bottom: 20px;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 100px;
}

.team-member {
  margin: 20px;
  text-align: center;
  position: relative;
}

.image-link {
  display: block;
  width: 400px;
  height: 400px;
  position: relative;
  text-decoration: none;
  border-radius: 8px;
  overflow: hidden;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.initial-image,
.hover-image {
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
}

.hover-image {
  opacity: 0;
}

.image-link:hover .hover-image {
  opacity: 1;
}

.image-link:hover .initial-image {
  opacity: 0;
}

.name-link {
  display: block;
  margin-top: 10px;
  font-size: 1.2rem;
  color: blue;
  text-decoration: none;
}

.name-link:hover {
  text-decoration: underline;
}

/* New description section with white background */
.member-description {
  margin-top: 10px;
  font-size: 1rem;
  color: black;
  background-color: white;
  padding: 10px;
  max-width: 400px;
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.5;
  max-height: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 10px;
}
