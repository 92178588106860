/* .partner-link {
  text-decoration: none;
  color: inherit;
}

.partner-link:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease;
}

.partners-container {
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: black;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 0;
  overflow-y: auto;
  margin-bottom: 40px;
}

.partners-container h1 {
  font-size: 5rem;
  margin-bottom: 20px;
}

.partners-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partner {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 800px;
  transition: box-shadow 0.3s ease;
}

.partner:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.partners-content .partner:last-child {
  margin-bottom: 60px;
}

.partner-image {
  max-width: 150px;
  height: auto;
  margin-right: 20px;
  border-radius: 50%;
}

.partner-text {
  text-align: left;
}

.partner-text h2 {
  margin: 0;
  font-size: 2rem;
}

.partner-text p {
  margin: 10px 0 0;
  font-size: 1rem;
} */

.partners-container {
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: black;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}

/* .partners-container {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: black;
  padding: 20px;
  text-align: center;
  position: relative;
  overflow-y: auto;
} */

.partners-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between items */
}

.partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px; /* Slightly wider for better text spacing */
  min-height: 420px; /* Ensures uniform height */
  text-align: center;
  transition: box-shadow 0.3s ease;
}

.partner:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.partner-image {
  max-width: 150px;
  height: 150px; /* Enforcing uniform height */
  object-fit: contain; /* Keeps image proportions */
  margin-bottom: 10px;
  border-radius: 50%;
}

.partner-text {
  text-align: center;
}

.partner-text h2 {
  margin: 0;
  font-size: 1.5rem;
  color: blue; /* Titles remain blue */
}

.partner-text p {
  margin: 10px 0 0;
  font-size: 1rem;
  color: black; /* Ensures text remains black */
  max-height: 9em; /* Allows more characters */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9; /* Limits to 9 lines */
  -webkit-box-orient: vertical;
  pointer-events: none; /* Prevents interaction */
}

.partner-link {
  text-decoration: none; /* Removes underlines */
  color: inherit; /* Ensures text color remains unchanged */
}
