.NFTproject-container {
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: black;
  padding: 1px;
  position: relative;
  z-index: 0;
}

.NFTproject-container h1 {
  font-size: 5rem;
  margin-bottom: 50px;
  text-align: center;
}

.NFTproject-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: left;
}

.NFTproject-image {
  max-width: 35%;
  height: auto;
  margin-right: 35px;
  margin-left: 35px;
  border-radius: 8px;
}

.NFTproject-text-block {
  flex: 1;
  background-color: white;
  padding: 20px;
  margin-right: 35px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.NFTproject-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
