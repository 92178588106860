footer {
  background-color: #282c34;
  color: white;
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

footer a {
  color: #61dafb;
  text-decoration: none;
  margin: 0 10px;
}

footer a:hover {
  text-decoration: underline;
}
