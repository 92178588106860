.codersaidfund-container {
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: black;
  padding: 20px;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}

.codersaidfund-container h1 {
  font-size: 5rem;
  margin-bottom: 20px;
  text-align: center;
}

.codersaidfund-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.codersaidfund-image {
  max-width: 35%;
  height: auto;
  margin-right: 25px;
  margin-top: 100px;
  border-radius: 8px;
}

.codersaidfund-text-block {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 100px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.codersaidfund-text-block h2 {
  margin-top: 0;
  font-size: 2rem;
  text-align: left;
}

.codersaidfund-text-block p {
  margin: 10px 0;
  font-size: 1rem;
  text-align: left;
}
