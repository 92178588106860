.media-container {
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: black;
  padding: 20px;
  position: relative;
  z-index: 0;
  margin-bottom: 40px;
}

.media-container h1 {
  font-size: 5rem;
  margin-bottom: 20px;
  color: black; /* Ensures visibility over the background */
}

.media-gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  border-radius: 8px;
}

.media-gallery a {
  display: block;
}

.media-gallery img {
  width: 400px; /* Adjusted size */
  height: 400px; /* Adjusted size */
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.media-gallery img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.media-text-box {
  margin-top: 40px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  color: black;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.media-text-box p {
  margin: 0;
  font-size: 1.2rem;
}

.media-container::before {
  content: "";
  background-image: url("../images/NFTbackground7.jpg");
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the background is behind the content */
}
