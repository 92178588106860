.gallery-container {
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: black;
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}

.gallery-container h1 {
  font-size: 5rem;
  margin-bottom: 20px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  border-radius: 8px;
}

.image-wrapper {
  position: relative;
  margin: 40px;
  width: 200px;
  height: auto;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  transition: transform 0.2s;
  border-radius: 8px;
}

.image-wrapper:hover img {
  transform: scale(1.1);
}

.image-text {
  margin-top: 10px;
  font-size: 1.2rem;
  color: white;
  text-align: center;
}

.social-share {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-share a {
  color: white;
  font-size: 1.5rem;
}

.social-share a:hover {
  color: #61dafb;
}

.gallery-text {
  /* margin-top: 20px; */
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  font-size: 1.2rem;
  border-radius: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}
